<template>
 <div>
  <v-row class="d-flex justify-center ">
    <v-col md="10" class="py-15" v-if="$store.state.activeDash">
      <v-row class="d-flex justify-center" >
      <v-col md="2" v-for="item in itemList" @click="fetchDrawer(item)" style="cursor: pointer;" >
        <v-row class="justify-center py-5">
          <v-card class="justify-center toolbar-card" >
                  <v-img class="toolbar-img" :src="item.icon" v-if="item.icon"/>
                  <!-- <v-icon size="40" class="toolbar-img">{{item.icon}}</v-icon> -->
          </v-card>
        </v-row>
        <v-row class="justify-center">
          <h5 >{{ $t(item.title) }}</h5>
        </v-row>
      </v-col>
      </v-row>
    </v-col>
    <v-row v-else >
      <v-col md="12" >
        <v-container class="d-flex justify-center">
            <div style=" width: 560px">
              <v-fade-transition mode="out-in">
                <!-- <v-img src="@/assets/robogex.png" style="width: 90%; position: relative; top: 1%;"></v-img> -->
              
                <v-img v-if="$store.state.isDarkMode" src="@/assets/Logo incen.png" style=" width: 90%; position: relative; top: 20%;"></v-img>
                <v-img v-if="!$store.state.isDarkMode" src="@/assets/Logo 2.png" style=" width: 90%; position: relative; top: 20%;"></v-img>
              </v-fade-transition>
            </div>
        </v-container>
        <v-col md="12" class="d-flex justify-center py-10">
        <v-col md="9" >
          <h2 >V3.5.7</h2>
        </v-col>
      </v-col>
      </v-col>
      
    </v-row>
  </v-row>
 </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
// import Drawer from "./components/core/Drawer.vue";

export default {
  name: "DashboardDashboard",

  data() {
    return {
      itemList: [
      {
        icon: require("@/assets/Aiai.png"),
        title: "Ai",
        to: "/aiIndexReport",
        role: [
          
        ],
        enterRoute: true,
        children: [
        {
            icon: "mdi-chart-bar",
            title: "Reports",
            to: "/aiIndexReport",
            enterRoute: true,
          },
          {
            icon: "mdi-view-dashboard-edit-outline",
            title: "Ai Dashbord",
            to: "/aiDashbordIndex",
            role: [],
            enterRoute: true,
          },
          // {
          //   icon: "mdi-star-outline",
          //   title: "Warehouse",
          //   to: "/warehouse",
          //   role: ['delivery_note:index', 'delivery_note:orders',],
          //   enterRoute: true,
          // },
        ],
      },
      {
        icon: require("@/assets/Warehouse.png"),
        title: "inventory",
        to: "/inventories",
        // To be filled with the required permission
        role: [
          'inventory:show',
          'inventory:auto-complete',
          'inventory:store',
          'inventory:update',
          'inventory:delete',
          'inventory-folder:index',
          'inventory-folder:store',
          'inventory-folder:auto-complete',
          'inventory-folder:update',
          'inventory-folder:show',
          'inventory-folder:delete',
          'inventory-material:store',
          'inventory-material:update',
          'inventory-material:destroy',
          'inventory-material:auto-complete',
          'inventory-material:store-material-attribute',
          'inventory-material:update-material-attribute',
          'inventory-material:destroy-material-attribute',
          'invoice-type:index',
          'invoice-type:store',
          'invoice-type:update',
          'invoice-type:show',
          'invoice-type:delete',
          'invoice-template:index',
          'invoice-template:store',
          'invoice-template:update',
          'invoice-template:show',
          'invoice-template:delete',
          'invoice:index',
          'invoice:store',
          'invoice:update',
          'invoice:toggle-lock',
          'invoice:show',
          'invoice:delete'
        ],
        enterRoute: true,
        children: [
          {
            icon: "mdi-file-cabinet",
            title: "inventory",
            // To be filled with the required permission
            enterRoute: true,
            to: "/inventories",
          },
          {
            icon: "mdi-family-tree",
            title: "material-tree",
            // To be filled with the required permission
            enterRoute: true,
            to: "/material-tree",
          },
          {
            icon: "mdi-family-tree",
            title: "bill-management",
            to: "/bill-management",
            enterRoute: true,
          },
          {
            icon: "mdi-family-tree",
            title: "Bills",
            to: "/invoice-list",
            enterRoute: true,
          },
          {
            icon: "mdi-family-tree",
            title: "Material Types",
            to: "/material-types",
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "invoiceTemplate",
            to: "/invoice-Template",
            enterRoute: true,
          },
          {
            icon: "mdi-chart-bar",
            title: "Inventory Reports",
            to: "/inventoryReports",
            enterRoute: true,
          },
        ],
      },
      {
        icon: require("@/assets/woke flow.png"),
        title: "WorkFlow",
        to: "/purchaseManagement",
        role: [
          'workflow:purchase',
          'workflow:sales',
          'workflow:order:update',
          'workflow:order:show',
          'workflow:order:delete',
          'delivery_note:index',
          'delivery_note:delete',
          'delivery_note:orders',
          'delivery_note:show',
          'delivery_note:update',
          'delivery_note:store',
          'workflow:order:confirm',
          'workflow:approve',
          'workflow:to_invoice',
        ],
        enterRoute: true,
        children: [
          {
            icon: "mdi-star-outline",
            title: "Purchase Management",
            to: "/purchaseManagement",
            role: ['workflow:purchase',],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "Sales Management",
            to: "/salesManagement",
            role: ['workflow:sales',],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "Warehouse",
            to: "/warehouse",
            role: ['delivery_note:index', 'delivery_note:orders',],
            enterRoute: true,
          },
        ],
      },
      {
        icon: require("@/assets/accounting.png"),
        title: "accounting",
        enterRoute: true,
        role: [
          "folder:index",
          "folder:store",
          "account:store",
          "entry:index",
          "entry-template:index",
          "financial-order:index",
          'app_financial_order:index',
          "financial-order:store",
        ],
        children: [
          {
            icon: "mdi-family-tree",
            title: "accounting tree",
            to: "/accounting",
            role: [
              "folder:index",
              "folder:store",
              "account:store",
              "cost-center:index",
            ],
            enterRoute: true,

          },
          {
            icon: "mdi-star-outline",
            title: "Sub Accounts",
            to: "/subAccount",
            role: [
              "sub_account:index",
              "sub_account:store",
            ],
            enterRoute: true,

          },
          {
            icon: "mdi-currency-usd",
            title: "cost center",
            to: "/costCenters",
            role: ["cost-center:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-transit-connection-horizontal",
            title: "Accounting entry",
            to: "/accountingEntry",
            role: ["entry:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-apps-box",
            title: "accounting templates",
            to: "/accountingTemplates",
            role: ["entry-template:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "financial orders",
            to: "/financialOrder",
            role: ["financial-order:index", "financial-order:store"],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "Financial Orders Parents",
            to: "/financialOrderParents",
            role: ["app_financial_order:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-family-tree",
            title: "Document Management",
            to: "/document-management",
            enterRoute: true,
          },
          {
            icon: "mdi-family-tree",
            title: "Documents",
            to: "/documents-list",
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "pay installments",
            to: "/payInstallments",
            role: ["financial-order:index", "financial-order:store"],
            enterRoute: true,
          },
          {
            icon: "mdi-chart-bar",
            title: "Financial Reports",
            to: "/reports",
            role: ['account:trailbalance','account:statement','tax:report','digital_wallet_record:report'],
            enterRoute: true,
          },
          {
            icon: "mdi-shape-circle-plus",
            title: "twirling",
            to: "/accountingRotation",
            role: ["cost-center:index"],
            enterRoute: true,
          },
        ],
      },
      {
        icon: require("@/assets/resturant.png"),
        title: "Restaurant",
        enterRoute: true,
        role: ["product:index", "purchase-history:store"],
        children: [
          {
            icon: "mdi-cart-variant",
            title: "Point Of Sale",
            to: "/buffet",
            enterRoute: true,
            role: ["purchase-history:store", "product:index"],
          },
          {
            icon: "mdi-food-fork-drink",
            title: "meals",
            role: ["meal:index"],
            to: "/meal",
            enterRoute: true,
          },
          // {
          //   icon: "mdi-history",
          //   title: "ordered meals",
          //   to: "/orderedMeals",
          //   role: ["student_meal:index"],
          //   enterRoute: true,
          // },
          {
            icon: "mdi-food-outline",
            title: "Meal Manufacturing",
            to: "/mealManufacturing",
            role: [],
            enterRoute: true,
          },
          // {
          //   icon: "mdi-invoice-list-outline",
          //   title: "orders invoices",
          //   to: "/ordersInvoices",
          //   role: [],
          //   enterRoute: true,
          // },
          {
            icon: "mdi-food-variant",
            title: "Food Categories",
            to: "/foodCategories",
            role: ["student_meal:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-chart-bar",
            title: "Meals Reports",
            to: "/meal/reports",
            role: ["student_meal:index"],
            enterRoute: true,
          },
          // {
          //   icon: "mdi-baguette",
          //   title: "cafeteria products",
          //   to: "/buffetProducts",
          //   role: ["product:index"],
          //   enterRoute: true,
          // },
        ],
      },
      {
        icon: require("@/assets/cashier.png"),
        title: "cashier",
        role: [
          "financial-order:index",
          "cashier-box:show",
          "cashier-box:index",
        ],
        enterRoute: true,
        children: [
          {
            icon: "mdi-star-outline",
            title: "Cashier for financial orders",
            to: "/cashierFinancialOrder",
            role: ["financial-order:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-account-group",
            title: "treasurers",
            to: "/cashierBox",
            role: ["cashier-box:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-archive",
            title: "boxes",
            to: "/myBoxes",
            enterRoute: true,
            role: ["cashier-box:show"],
          },
        ],
      },
      {
        icon: require("@/assets/group.png"),
        title: "Users",
        enterRoute: true,
        role: ["user:index"],
        children: [
            {
            icon: "mdi-account-tie",
            title: "roles",
            to: "/roles",
            enterRoute: true,
            role: ["role:index"],
          },
          {
            icon: "mdi-account-star",
            title: "admins",
            to: "/admins",
            enterRoute: true,
            role: ["user:index"],
          },
          {
            icon: "mdi-account-supervisor-circle",
            title: "parents",
            to: "/parents",
            enterRoute: true,
            role: ["parent:index"],
          },
        ],
      },
      {
        icon: require("@/assets/students.png"),
        title: "students",
        role: ["student:index", "final_grade:student:index"],
        enterRoute: true,
        children: [
          {
            icon: "mdi-star-outline",
            title: "Student Info",
            to: "/students",
            role: ["student:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "Results Document",
            to: "/graduation",
            role: ["final_grade:student:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "Variables",
            to: "/variablesStudent",
            role: ["school_variable:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-account-group",
            title: "temporary zone",
            to: "/temporary-area",
            role: [],
            enterRoute: true,
          },
          {
            icon: "mdi-chart-bar",
            title: "Students Reports",
            to: "/students/reports",
            role: ["absent:report", "mark:report"],
            enterRoute: true,
          },
        ],
      },
      {
        icon: require("@/assets/bus.png"),
        title: "routes",
        role: ["route:index", "driver:index", "trip:report", "student_trip:report"],
        enterRoute: true,
        children: [
          {
            icon: "mdi-highway",
            title: "Trips",
            role: ["route:index"],
            enterRoute: true,
            to: "/driversRoutes",
          },
          {
            icon: "mdi-car",
            title: "drivers",
            role: ["driver:index"],
            to: "/drivers",
            enterRoute: true,
          },
          {
            icon: "mdi-chart-bar",
            title: "Drivers Reports",
            to: "/drivers/reports",
            role: ["trip:report", "student_trip:report"],
            enterRoute: true,
          },
        ],
      },
      {
        icon: require("@/assets/academic.png"),
        title: "Educational section",
        role: [
          'teacher:store',
          'teacher:show',
          'teacher:index',
          'teacher:delete',
          'teacher:update', "edu-class:index", "edu-class:store", "edu-class:show", "edu-class:update", "edu-class:destroy",
          'responsibility:store',
          'responsibility:index',
          'responsibility:show',
          'responsibility:delete',
          'responsibility:update',
          'lesson:index',
        ],
        enterRoute: true,
        children: [
          {
            icon: "mdi-account-multiple",
            title: "teachers",
            to: "/teachers",
            role: ['teacher:index'],
            enterRoute: true,
          },
          {
            icon: "mdi-book-open",
            title: "Weekly classes",
            to: "/weekly-classes",
            role: ['responsibility:index',],
            enterRoute: true,
          },

          {
            icon: "mdi-book-multiple",
            title: "lessons",
            to: "/teacher-lessons",
            role: ["lesson:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-book",
            title: "Homeworks",
            to: "/homeworks",
            role: ["student:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-attachment",
            title: "attachments",
            to: "/attachments",
            role: ["student:index"],
            enterRoute: true,
          },
          {
            icon: "mdi-chart-bar",
            title: "Reports",
            to: "/reportTechers",
            role: ["student:index"],
            enterRoute: true,
          },
        ],
      },
      {
        icon: require("@/assets/lms 2.png"),
        title: "L.M.S",
        role: [ 'question:index','quizze:index','lms:reports'
        ],
        enterRoute: true,
        children: [
          {
            icon: "mdi-star-outline",
            title: "Questions",
            to: "/questionsLms",
            role: ['question:index'],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "Quizzes",
            to: "/quizzesLms",
            role: ['quizze:index'],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "Reports",
            to: "/lmsReports",
            role: ['lms:reports'],
            enterRoute: true,
          },
        ],
      },
      {
        icon: require("@/assets/main view.png"),
        title: "main views",
        to: "/mainViews",
        enterRoute: true,
        role: [
          "educational-year:index",
          "term:index",
          "term-type:index",
          "grade:index",
          "grade-type:index",
          "floor:index",
          "room:index",
          "edu-class:index",
          "subject:index",
        ],
        children: [
        {
          icon: "mdi-star-outline",
          title: "educational year",
          to: "/educationalYear",
          role: "educational-year:index",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "terms",
          to: "/terms",
          role: "term:index",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "terms type",
          to: "/termTypes",
          role: "term-type:index",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "calsses",
          to: "/grades",
          role: "grade:index",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "classes type",
          to: "/gradeTypes",
          role: "grade-type:index",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "floors",
          to: "/floors",
          role: "floor:index",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "rooms",
          to: "/rooms",
          role: "room:index",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "education class",
          to: "/educationClass",
          role: "edu-class:index",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "subjects",
          to: "/subjects",
          role: "subject:index",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "Grade Structure",
          to: "/gradeStructure",
          role: "grade-structure:index",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "Backup",
          to: "/backup",
          role: "backup:index",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "Log",
          to: "/log",
          role: "log:report",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "Variables",
          to: "/variables",
          role: "school_variable:index",
          enterRoute: true,
        },
        
        {
          icon: "mdi-star-outline",
          title: "Taxes",
          to: "/taxes",
          // role: "",
          enterRoute: true,
        },
        {
          icon: "mdi-star-outline",
          title: "settings",
          to: "/settings",
          role: "settings:index",
          enterRoute: true,
        },
        // {
        //   icon: "mdi-star-outline",
        //   title: "Currencies",
        //   to: "/currencies",
        //   role: "currencies:index",
        //   enterRoute: true,
        // },
      ],
      },
      {
        icon: require("@/assets/HR.png"),
        title: "HR",
        enterRoute: true,
        role: [
          "folder:index",
          "folder:store",
          "account:store",
          "entry:index",
          "entry-template:index",
          "financial-order:index",
          "financial-order:store",
        ],
        children: [
          {
            icon: "mdi-cog",
            title: "iniate",
            to: "/HR/initiate",
            role: [],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "public record",
            to: "/HR/PublicRecord",
            role: [],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "hr.time-attendance",
            to: "/HR/time-attendance",
            role: [],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "hr.vacations",
            to: "/HR/vacations",
            role: [],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "hr.variations",
            to: "/HR/variations",
            role: [],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "hr.loans",
            to: "/HR/loans",
            role: [],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "hr.calculations",
            to: "/HR/calculations",
            role: [],
            enterRoute: true,
          },
          {
            icon: "mdi-star-outline",
            title: "hr.payments",
            to: "/HR/payments",
            role: [],
            enterRoute: true,
          },
          {
            icon: "mdi-chart-bar",
            title: "HR Reports",
            to: "/HR/reports",
            role: [],
            enterRoute: true,
          },
        ],
      },
      {
        icon: require("@/assets/maling 2.png"),
        title: "Mailing",
        enterRoute: true,
        role: [
          'message:index',
          'message:show',
          'message:mail',
          'message:send_mail',
          'message:relpy',
          'message:update',
          'message:document',
          'message:document',
          'message:delete',
          'task:index',
          'task:receive',
          'task:send',
          'task:export',
          'task:done',
          'task:approve',
        ],
        children: [
          {
            icon: "mdi-mail",
            title: "Incoming Mail",
            to: "/incomingMail",
            role: ['message:mail',],
            enterRoute: true,
          },
          {
            icon: "mdi-mail",
            title: "Messages Sent",
            to: "/messagesSent",
            role: ['message:send_mail'],
            enterRoute: true,
          },
          {
            icon: "mdi-mail",
            title: "All Messages",
            to: "/allMessages",
            role: ['message:index',],
            enterRoute: true,
          },
          {
            icon: "mdi-handshake",
            title: "My Tasks",
            to: "/myTasks",
            role: ['task:receive',],
            enterRoute: true,
          },
          {
            icon: "mdi-handshake",
            title: "Tasks Sent",
            to: "/tasksSent",
            role: ['task:send',],
            enterRoute: true,
          },
          {
            icon: "mdi-handshake",
            title: "All Tasks",
            to: "/allTasks",
            role: ['task:index',],
            enterRoute: true,
          },
        ]
      },
      {
        icon: require("@/assets/log off 2.png"),
        title: "logout",
        to: "/Login",
        enterRoute: true,
        children: null,
      },

    ],
    };
  },
  computed: {
    
    ...mapState(["drawer"]),
  },
  methods: {
    getImgUrl(url){
      let u = "";
      u = url;
      console.log(u);
      return  u;
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER",
      setDrawerList: "SET_DRAWER_LIST",
    }),
   fetchDrawer(item) {
      if(item.children){
        this.$store.state.drawerList = item.children;
        localStorage.setItem("drawerList",JSON.stringify(item.children));
        this.$store.state.drawer = true;
        this.$store.state.activeDash = false;
      } else {
        if(item.to=='/Login')
        localStorage.clear();
        axios.defaults.headers.common["Authorization"] = null;
        axios.defaults.headers.common["term"] = null;
        this.$router.replace(`${item.to}`);
      }
    // this.setDrawerList(list);
    // localStorage.setItem('DrawerList', JSON.stringify(list));
    // this.setDrawer(!this.drawer);
    // localStorage.setItem('Drawer', true);
    // let to = list[0].to;
    // this.$router.push(`${to}`);
    
   },
  },
  created(){
    this.$store.state.drawer = false;
  }
};
</script>
<style lang="scss">

.toolbar-card {
 
  display: flex;
  height: 80px;
  width: 80px;
  border-radius: 15px !important;
  
  text-align: center !important;

  transition: 0.5s;
}

.toolbar-img {
  display: flex;
  height: 75px;
  width: 7px;
  border-radius: 15px;
  justify-content: center;
  text-align: center;
  justify-items: center;
  transition: 0.5s;
}

</style>
